<template>
  <div class="infoBox-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_infoBox"
        :hoverInfo="translations.labels.button_add_infoBox_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList :is-ready="isPageReady" :headers="headers" :items="list" />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import TablePanel from "@/components/panel/TablePanel";
import TableList from "@/components/table/TableList";
import ButtonBase from "@/components/dom/ButtonBase";

@Component({
  components: {
    ActionPanel,
    TablePanel,
    TableList,
    ButtonBase,
  },
  inject: {
    infoBoxService: "infoBoxService",
    toastService: "toastService",
  },
})
export default class InfoBox_list extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async init() {
    try {
      this.model = await this.infoBoxService.list(
        this.queryParameters.idParent
      );
      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get list() {
    return this.model;
  }

  get queryParameters() {
    return this.$route.params;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.liveSession_table_code,
        value: "code",
      },
      {
        text: this.translations.labels.link_table_width,
        value: "width",
      },
      {
        text: this.translations.labels.link_table_height,
        value: "height",
      },
      {
        text: this.translations.labels.link_table_x,
        value: "x",
      },
      {
        text: this.translations.labels.link_table_y,
        value: "y",
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        formatValue: (item) => {
          return `/info-box/detail/${this.queryParameters.idParent}/${item.id}`;
        },
        icon: "mdi-feature-search-outline",
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/info-box/edit/${this.queryParameters.idParent}/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
    ];
  }

  async swapEnabled(_id, _isEnabled) {
    this.isPageReady = false;
    try {
      if (!_isEnabled) await this.infoBoxService.enable(_id);

      if (_isEnabled) await this.infoBoxService.disable(_id);
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }

    this.model = await this.infoBoxService.list(this.queryParameters.idParent);
    this.toastSuccessEnabledLabel(_isEnabled);
    this.isPageReady = true;
  }

  toastSuccessAvailabilityLabel(isEnabled) {
    isEnabled
      ? this.toastService.success(this.translations.success.infoBox_notavailable)
      : this.toastService.success(this.translations.success.infoBox_available);
  }
  toastSuccessEnabledLabel(isEnabled) {
    isEnabled
      ? this.toastService.success(this.translations.success.infoBox_disable)
      : this.toastService.success(this.translations.success.infoBox_enable);
  }

  newItem() {
    this.$router.push(`/info-box/new/${this.queryParameters.idParent}`);
  }
  created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.infoBox_list
    );
  }
  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.infoBox-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }
}
</style>
